import React, { useState, useImperativeHandle, useRef, useEffect } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import { description } from "../../data";
import { portfolioType } from "../consts/config";
import Resume from "../../data/resume.pdf";
import Image from "../elements/Image";
import LinkedInLogo from "../../assets/images/linkedin.png";
import GithubLogo from "../../assets/images/github.png";
import HackerRankLogo from "../../assets/images/hackerrank.svg";
import ResumeLogo from "../../assets/images/resume.svg";
import { openInNewTab } from "../../utils/commons";
import StarsCanvas from "../Stars/Stars";
import { PC } from "../PC/PC";
import { useOnScreen } from "../../hooks/useOnscreen";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = React.forwardRef(
  (
    {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      radioRef,
      ...props
    },
    ref
  ) => {
    const [isMobile, setIsMobile] = useState(false);
    const heroRef = useRef(null);
    useImperativeHandle(ref, () => {
      return { scrollToComponent };
    });
    const onScreen = useOnScreen(heroRef);

    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth <= 768);
    }
    useEffect(() => {
      if (onScreen) {
        radioRef.current.setRadioVal("hero");
      }
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, [onScreen]);
    const scrollToComponent = () => {
      heroRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = (e) => {
      e.preventDefault();
      setVideomodalactive(true);
    };

    const closeModal = (e) => {
      e.preventDefault();
      setVideomodalactive(false);
    };

    const outerClasses = classNames(
      "hero section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <>
        <section {...props} className={outerClasses}>
          <div
            className="container-sm"
            ref={heroRef}
            style={{ position: "sticky", zIndex: 1 }}
          >
            <div className={innerClasses} style={{ paddingTop: 0 }}>
              <div className="hero-content">
                <h1
                  className="mt-0 mb-16 reveal-from-bottom"
                  data-reveal-delay="200"
                >
                  Mubasher Ahmad
                </h1>
                <p
                  className="mt-0 mb-16 reveal-from-bottom"
                  data-reveal-delay="200"
                >
                  <span style={{ color: "#3498DB" }}>Software Engineer</span>
                </p>

                <div className="container-xs">
                  <p
                    className="m-0 mb-32 reveal-from-bottom"
                    data-reveal-delay="400"
                  >
                    {description[portfolioType]}
                  </p>
                </div>
                {/* Buttons */}
                <div className="container-xs">
                  <div
                    // className="reveal-from-bottom"
                    data-reveal-delay="600"
                    style={{ marginTop: 30 }}
                  >
                    <ButtonGroup
                      style={{
                        gap: "5%",
                      }}
                    >
                      <Image
                        src={LinkedInLogo}
                        alt="icon"
                        width={44}
                        height={44}
                        onClick={() =>
                          openInNewTab(
                            "https://www.linkedin.com/in/mubasher-ahmad-316164180/"
                          )
                        }
                        style={{
                          cursor: "pointer",
                          margin: 0,
                        }}
                      />

                      <Image
                        src={GithubLogo}
                        alt="icon"
                        width={44}
                        height={44}
                        onClick={() =>
                          openInNewTab("https://github.com/mubasher117")
                        }
                        style={{
                          cursor: "pointer",
                          margin: 0,
                        }}
                      />
                      <Image
                        src={HackerRankLogo}
                        alt="icon"
                        width={56}
                        height={56}
                        onClick={() =>
                          openInNewTab("https://www.hackerrank.com/mubasher117")
                        }
                        style={{
                          cursor: "pointer",
                          margin: 0,
                        }}
                      />
                      <Image
                        src={ResumeLogo}
                        alt="icon"
                        width={44}
                        height={44}
                        onClick={() => openInNewTab(Resume)}
                        style={{
                          cursor: "pointer",
                          margin: 0,
                        }}
                      />
                    </ButtonGroup>
                    {/* <ButtonGroup
                      style={{
                        gap: "5%",
                        marginTop: 60,
                      }}
                    >
                      <Button
                        tag="a"
                        // color="primary"
                        wideMobile
                        href={Resume}
                        target="_blank"
                        style={{ backgroundColor: "#3498DB", color: "white" }}
                      >
                        View Resume
                      </Button>
                    </ButtonGroup> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && <PC />}
        </section>
      </>
    );
  }
);

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
