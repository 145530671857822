import React from "react";
import { projects } from "../../data";
import { portfolioType } from "../../consts/config";
import "./index.css";
import Image from "../elements/Image";

export const FooterProjects = () => {
  return (
    <div className="main-container projects-heading">
      <h4>Projects</h4>
      <div className="projects-container footer-details-rep">
        {projects[portfolioType]?.map((project, index) => {
          if (index < 4)
            return (
              <div className="footer-project" key={`div-${index}`}>
                <Image
                  src={project.thumbnail}
                  alt="Project Image"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            );
        })}
      </div>
    </div>
  );
};
