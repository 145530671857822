import React from "react";
import FnbLogo from "../assets/images/fnb.jpeg";
import PaymobLogo from "../assets/images/paymob-logo.jpeg";
import TkxelLogo from "../assets/images/tkxel.png";
import DipleoLogo from "../assets/images/dipleo.png";
import LocoLogo from "../assets/images/loco-logo.png";
import LocoHome from "../assets/images/loco.png";
import DeckLogo from "../assets/images/deck.png";
import KICKSLogo from "../assets/images/kicks.png";
import ConciseSolutionsLogo from "../assets/images/concise-solutions.jpeg";
import PanteraLogo from "../assets/images/pantera-logo.jpeg";
import TrailzLogo from "../assets/images/trailz.png";
import ECopiesLogo from "../assets/images/eCopies.png";
import TrailzNavLogo from "../assets/images/trailz-nav.png";
import TrailzGroupsLogo from "../assets/images/trailz-groups.png";
import EcopiesHomeLogo from "../assets/images/ecopies-home.png";
import EcopiesOrdersLogo from "../assets/images/ecopies-orders.png";
import NiivanHomeImage from "../assets/images/niivana-home.png";
import NiivanPaymentImage from "../assets/images/niivana-payment.png";
import NiivanaLogo from "../assets/images/niivana-logo.png";
import CsdLogo from "../assets/images/csd.jpeg";

export const description = {
  react: "",
  rn: "As an accomplished software engineer with over four years of professional experience, I specialize in ReactNative, ReactJS, Django Rest Framework, and Python. With a strong track record of delivering high-quality software solutions and a passion for staying up-to-date with industry advancements, I possess the skills necessary to navigate complex challenges and drive optimal results.",
  python:
    "As an python software engineer with over four years of professional experience, I specialize in ReactNative, ReactJS, Django Rest Framework, and Python. With a strong track record of delivering high-quality software solutions and a passion for staying up-to-date with industry advancements, I possess the skills necessary to navigate complex challenges and drive optimal results.",
  // rn: `Experiencing more than 3 years in intensive development, I have expertise in app development for ios, ipad and android in
  // React Native. I have launched apps on play store and app store. I have worked with individuals and larger companies on apps
  // from MVP to enterprise level.
  // `,
};

export const workExperience = [
  {
    companyName: "Paymob",
    companyLogo: PaymobLogo,
    title: "Software Engineer",
    start: "November 2022",
    end: "Present",
    link: "https://www.paymob.com/",
    workDescription: {
      react: "Worked on several",
      rn: `Led a team of developers to deliver projects on-time and within budget.
        Designed, developed, and maintained React Native applications for iOS and Android platforms.
        Collaborated with UX designers and back-end engineers to deliver high-quality products.
        Implemented industry best practices, such as version control, continuous integration, and unit testing.`,
      python: `Python Engineer`,
    },
  },
  {
    companyName: "Tkxel",
    companyLogo: TkxelLogo,
    title: "Software Engineer",
    start: "March 2022",
    end: "October 2022",
    link: "https://www.tkxel.com/",
    workDescription: {
      react: "Worked on several",
      rn: `Led a team of developers to deliver projects on-time and within budget.
        Designed, developed, and maintained React Native applications for iOS and Android platforms.
        Collaborated with UX designers and back-end engineers to deliver high-quality products.
        Implemented industry best practices, such as version control, continuous integration, and unit testing.`,
    },
    python: `Python Engineer`,
  },
  // {
  //   companyName: "Decklaration",
  //   companyLogo: DeckLogo,
  //   title: "Software Engineer",
  //   start: "September 2021",
  //   end: "February 2022",
  //   link: "https://decklaration.com/",
  //   workDescription: {
  //     react:
  //       "Designed and developed backend of a crypto project in Django." +
  //       "Used Protocol Buffers to store data in Google Data Store." +
  //       "Focused on testing and deployment of code with Python Unit Test Framework  and Google Bazel respectively.",
  //     rn: `Worked here on a hybrid mobile app related to crypto currency wallet in a team of 10 members. Responsibilities here
  //     included conversion of Figma designs to App UI, integrated APIs and integrate libraries for various purposes.
  //     Focused on app security, unit testing and code quality.
  //     `,
  //   },
  // },
  {
    companyName: "Funnel Beam",
    companyLogo: FnbLogo,
    title: "Software Engineer",
    start: "April 2020",
    end: "March 2022",
    link: "https://www.funnelbeam.com/",
    workDescription: {
      react:
        "Developed FunnelBeam Customer App in ReactJS." +
        "Developed FNB App’s Backend in Django." +
        "Integrated Salesforce to FNB App via OAuth." +
        "Deployed websites and web apps on AWS and GCP." +
        "Built robust Python modules for automatic data enrichment and posting to CRM (Salesforce and Eloqua).",
      rn: `Worked on client based mobile app projects in React Native based mostly in US. Small projects but solving complex problems was 
      a part of daily routine here. Worked in a team of 2 devs. Took special care of on time delivery and app quality. 
      Remote work and team coordination.`,
    },
  },

  {
    companyName: "Concise Solutions",
    companyLogo: ConciseSolutionsLogo,
    title: "Full Stack Developer",
    start: "April 2019",
    end: "March 2020",
    link: "https://www.linkedin.com/company/concise-solutions/about/",
    workDescription: {
      react:
        "Developed web apps’ front end  in  React JS and backend in Django Rest Framework." +
        "Worked on e-Commerce apps and job portals." +
        ",Worked in a team of 3 people. Used Git for version controlling.",
      rn: `Worked on projects related to job portal. A team of 3 including 1 mobile and 2 backend devs.`,
    },
  },
  // {
  //   companyName: "KICKS",
  //   companyLogo: KICKSLogo,
  //   title: "Intern Web Developer",
  //   start: "June 2019",
  //   end: "September 2019",
  //   link: "https://kics.edu.pk/web/",
  //   workDescription: {
  //     react: "I'm currently working here as a Software Engineer. ",
  //     rn: "Worked on React JS. Integrated ML models based APIs in web app. Converted Figma designs to React JSX.",
  //   },
  // },
  // {
  //   companyName: "PANTERA Energy",
  //   companyLogo: PanteraLogo,
  //   title: "Intern Web Developer",
  //   start: "June 2018",
  //   end: "September 2018",
  //   link: "https://panteraenergy.pk/",
  //   workDescription: {
  //     react: "I'm currently working here as a Software Engineer. ",
  //     rn: "Worked on React JS. Integrated APIs in web app. Converted Figma designs to React JSX.",
  //   },
  // },
];

export const projects = {
  react: [
    {
      title: "Loco",
      description:
        "I worked in it as a Full Stack Developer. Converted figma designs to JSX." +
        "Developed backend in django in a team of 2 devs." +
        "Integrated Chat System and Payment System in it. I worked in a team of one front end engineer and two backend engineer including myself.",
      thumbnail: LocoLogo,
      link: "https://loco-app-28024.botics.co/",
      technologies:
        "React JS, Django REST Framework, SQLite, Bootstrap, HTML/CSS",
      duration: "8 months",
    },
    {
      title: "Dipleo",
      description:
        "An online job finding platform. It was developed by a team of two people. I worked as a front end developer. I was given some basic UI (HTML/CSS) of the web app. I integrated all APIs that were developed by the other team member and also added various dynamic features like video recording from web cam, loading widgets and some animations.. We used github as a version control tool.",
      thumbnail: DipleoLogo,
      technologies:
        "React JS, Django REST Framework, SQLite, Bootstrap, HTML/CSS",
      link: "https://www.dipleo.com/",
      duration: "5 months",
    },
    {
      title: "Niivana",
      description:
        "I worked in it as a Full Stack Developer in React JS and Django. I worked with a team of 2 frontend engineers and 3 backend engineers including myself. We used Github for version controlling.",
      thumbnail: NiivanaLogo,
      technologies:
        "React JS, Django REST Framework, SQLite, Bootstrap, HTML/CSS, Redux, Zoom SDK",
      link: "https://www.dipleo.com/",
      duration: "3 months",
    },
  ],
  rn: [
    {
      title: "Trailz App",
      description: `Led a team of 8 developers to design and develop the Trailz app, the first of its kind in the USA to provide off-road trail navigation for ATV riders in Wisconsin, Minnesota, and Michigan.
      Utilized React Native extensively to create a user-friendly interface and ensure cross-platform compatibility.
      Worked closely with the product manager to define requirements and prioritize features based on user needs and feedback.
      Collaborated with the backend team to integrate APIs and ensure seamless communication between the app and server.
      Implemented various features including drawing trails on map, precise location tracking, in app purchase for subscriptions.
      Conducted thorough testing and debugging to ensure the app was reliable and met the highest standards of quality and performance.
      Maintained the codebase, managed version control, and ensured timely delivery of project milestones.
      Demonstrated exceptional leadership, communication, and problem-solving skills throughout the project, resulting in a successful product launch and high user engagement.`,
      thumbnail: TrailzLogo,
      images: [TrailzNavLogo, TrailzGroupsLogo],
      technologies:
        "React Native, Django REST Framework, Redux, Firebase, Google Maps.",
      links: {
        appStore: "https://apps.apple.com/us/app/trailz-lets-ride/id1565929427",
        playStore:
          "https://play.google.com/store/apps/details?id=com.aspect.trailz",
        // github: "https://github.com/mubasher117/trailz-git.git",
      },
      duration: "6 months",
      isMobileApp: true,
    },
    {
      title: "eCopies",
      description: `I worked in it as a sole developer in React Native and React JS.
        Used expo client with React Native and firebase for backend services. This project includes an app and admin panel.
        `,
      thumbnail: ECopiesLogo,
      images: [EcopiesHomeLogo, EcopiesOrdersLogo],
      technologies: "React Native, Expo Client, Redux, Firebase",
      links: {
        playStore: "https://play.google.com/store/apps/details?id=com.ecopies",
        github: "https://github.com/mubasher117/eCopies",
      },
      duration: "3 months",
      isMobileApp: true,
    },
    {
      title: "Niivana",
      description: `This app includes video and text chatting, scheduled meeting and subscription based payments.
      I worked in it as a React Native Engineer in a team of 3 members. I worked on video chat, user profile, bookings and payments. `,
      thumbnail: NiivanaLogo,
      images: [NiivanHomeImage, NiivanPaymentImage],
      technologies: "React Native, Pubnub, Zoom Sdk, Stripe",
      links: { github: "https://github.com/mubasher117/niivana_replica" },
      duration: "3 months",
      isMobileApp: true,
    },
    {
      title: "Certified Safe Driver",
      description: `This app analyzes drivers' abilities of driving different vehicles by calibrating drivers' vehicles
      and analyzing drivers' behavior and driving skills. I worked in it as a Mobile Engineer to develop cross platform application.
      `,
      thumbnail: CsdLogo,
      images: [NiivanHomeImage, NiivanPaymentImage],
      technologies: "React Native, Redux, RN Maps",
      links: {
        github: "https://github.com/mubasher117/certified_safe_driver",
      },
      duration: "3 months",
      isMobileApp: true,
    },
    {
      title: "Loco App",
      description: `As a React Developer in this project, my responsibilities included working on Customer Onboarding, Group Chat and Payments modules. It was managed by a team
        of 3 members.`,
      thumbnail: LocoLogo,
      images: [LocoHome],
      links: { website: "https://loco-app-28024.botics.co/" },
      technologies:
        "React JS, Pubnub, Django REST Framework, SQLite, Bootstrap, HTML/CSS",
      duration: "6 months",
    },
    // {
    //   title: "MietBOX",
    //   description:
    //     "I worked in it as a React Native Engineer in React JS and Django. I worked with a team of 2 frontend engineers and 3 backend engineers including myself. We used Github for version controlling.",
    //   thumbnail: ECopiesLogo,
    //   images: [],
    //   technologies:
    //     "React JS, Django REST Framework, SQLite, Bootstrap, HTML/CSS, Redux, Zoom SDK",
    //   playStoreLink:
    //     "https://play.google.com/store/apps/details?id=com.verkherbox",
    //   duration: "3 months",
    //   isMobileApp: true,
    // },
    // {
    //   title: "The gameHers",
    //   description:
    //     "I worked in it as a React Native Engineer in React JS and Django. I worked with a team of 2 frontend engineers and 3 backend engineers including myself. We used Github for version controlling.",
    //   thumbnail: ECopiesLogo,
    //   images: [],
    //   technologies:
    //     "React JS, Django REST Framework, SQLite, Bootstrap, HTML/CSS, Redux, Zoom SDK",
    //     appStoreLink:
    //     "https://apps.apple.com/us/app/the-gamehers-meet-chat-play/id1567605133",
    //   duration: "3 months",
    //   isMobileApp: true,
    // },
    // {
    //   title: "Maktab Shinasi",
    //   description:
    //     "I worked in it as a React Native Engineer in React JS and Django. I worked with a team of 2 frontend engineers and 3 backend engineers including myself. We used Github for version controlling.",
    //   thumbnail: ECopiesLogo,
    //   images: [],
    //   technologies:
    //     "React JS, Django REST Framework, SQLite, Bootstrap, HTML/CSS, Redux, Zoom SDK",
    //   playStoreLink:
    //     "https://play.google.com/store/apps/details?id=com.maktabshinasi",
    //   duration: "3 months",
    //   isMobileApp: true,
    // },
  ],
};

export const testimonials = [
  {
    comment:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum cillum dolore eu fugiat.",
    author: "Aun Raza",
    designation: "Engineering Manager @ Paymob",
    profileLink: "https://www.linkedin.com/in/aunraz/",
  },
];
