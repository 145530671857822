import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { Project } from "../Project";
import { projects } from "../../data";
import { portfolioType } from "../consts/config";
import { useOnScreen } from "../../hooks/useOnscreen";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = forwardRef(
  (
    {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      radioRef,
      ...props
    },
    ref
  ) => {
    const projectsRef = useRef(null);
    useImperativeHandle(ref, () => {
      return { scrollToComponent };
    });
    const onScreen = useOnScreen(projectsRef);
    useEffect(() => {
      if (onScreen) {
        radioRef.current.setRadioVal("projects");
      }
    }, [onScreen]);
    const scrollToComponent = () => {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Projects",
      paragraph: "",
      // "I'm able to complete several project in the technologies I have expertise of.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container" ref={projectsRef}>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
              {projects[portfolioType]?.map((p, i) => (
                <Project
                  key={i}
                  title={p.title}
                  description={p.description}
                  thumbnail={p.thumbnail}
                  images={p.images}
                  technologies={p.technologies}
                  duration={p.duration}
                  isMobileApp={p.isMobileApp}
                  links={p.links}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
);

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
